import experiments from './experiments';
import maIntegrationKit from './ma-integration-kit';
import actions from './actions';
import paidPostMigration from './paid-post-migration';
import oldBlogMigration from './old-blog-migration';
import menu from './menu';
import pageService from './page';
import {
  MANAGE_POSTS_EVENT,
  SITE_WAS_FIRST_SAVED,
  DELETE_BLOG_EVENT,
  WIDGET_GFPP_CLICKED,
  COMPONENT_GFPP_CLICKED,
  OPEN_WIDGET_MAIN_SETTINGS,
  OPEN_WIDGET_LAYOUT_SETTINGS,
} from '../constants/events';
import getAppManifest from './app-manifest';
import { OFFLINE_PAID_POST_MIGRATION_ID, MAGIC_MIGRATION_ID } from '../constants/migrations';
import { TPA_PAGE_ID_BLOG } from '../constants/tpa-pages';
import translation from './translation';
import translations from '../translations.json';
import { NEW_BLOG_QUICK_MIGRATION, NEW_BLOG_MAGIC_MIGRATION } from '../constants/experiments';
// import MagicMigration from './magic-migration';
// import { BLOG_WIDGET_ID, POST_LIST_WIDGET_ID } from '../constants/widgets';

export const handleEditorReady = async (context) => {
  await experiments.conduct();
  await Promise.all([
    experiments.conductSingle(NEW_BLOG_QUICK_MIGRATION, 'old'),
    experiments.conductSingle(NEW_BLOG_MAGIC_MIGRATION, 'old'),
  ]);
  const locale = await context.sdk.environment.getLocale();
  translation.init(translations, locale);

  return context.isADI
    ? actions.installMissingPostPage(context)
    : Promise.all([
        actions.installMissingPostPage(context),
        menu.addPages(context),
        actions.setStyleParams(context),
        // new MagicMigration(context, { dryRun: true, whiteList: [BLOG_WIDGET_ID, POST_LIST_WIDGET_ID] }).run(),
      ]);
};

export const handleBlogInstalled = async ({ sdk, appToken, isADI }) => {
  const provisionInitialized = await actions.initProvision({ sdk });

  await actions.initBiService({ sdk, appToken });

  const shouldMigrateOldBlog = await oldBlogMigration.shouldMigrate({ sdk, isADI });

  if (shouldMigrateOldBlog) {
    return oldBlogMigration.migrate({ sdk }, { provisionInitialized });
  }

  const isMAInstalled = await maIntegrationKit.isMembersAreaInstalled();
  return isMAInstalled || actions.displayProvisioningModal({ sdk, appToken, isADI });
};

export const handleGFPPClicked = ({ id, componentRef, context, componentRole }) => {
  if (id === OPEN_WIDGET_MAIN_SETTINGS) {
    actions.openMainSettings(context, componentRef, componentRole);
  }

  if (id === OPEN_WIDGET_LAYOUT_SETTINGS) {
    actions.openLayoutSettings(context, componentRef, componentRole);
  }
};

export const handleOnEvent = async ({ eventType, eventPayload }, context) => {
  switch (eventType) {
    case SITE_WAS_FIRST_SAVED:
      return actions.installMissingPostPage({ ...context, instance: eventPayload.instance });
    case MANAGE_POSTS_EVENT:
      return (
        context.sdk &&
        context.sdk.editor.openDashboardPanel(context.appToken, {
          url: 'blog',
          closeOtherPanels: false,
        })
      );
    case DELETE_BLOG_EVENT:
      const blogPage = await pageService.find({ ...context, tpaPageId: TPA_PAGE_ID_BLOG });
      return context.sdk.document.pages.remove(context.appToken, { pageRef: { id: blogPage.id } });
    case WIDGET_GFPP_CLICKED: {
      const { id, componentRef } = eventPayload;
      return handleGFPPClicked({ id, componentRef, context });
    }
    case COMPONENT_GFPP_CLICKED: {
      // componentRole would be used creating widget's inner component's settings panels
      const { id, controllerRef, role } = eventPayload;
      return handleGFPPClicked({ id, componentRef: controllerRef, context, componentRole: role });
    }
    default:
      break;
  }
};

export const handleGetAppManifest = ({ sdk, appToken }) => {
  if (!sdk) {
    return {};
  }

  return getAppManifest(sdk.info.getSdkVersion().scriptSrc, appToken);
};

export const handleMigrateAction = (context, { migrationId } = {}) => {
  switch (migrationId) {
    case OFFLINE_PAID_POST_MIGRATION_ID:
      return paidPostMigration.migrateOffline(context);
    case MAGIC_MIGRATION_ID:
      return oldBlogMigration.migrate(context, { skipComponentMapping: true });
    default:
      return Promise.resolve();
  }
};

export default {
  handleBlogInstalled,
  handleEditorReady,
  handleOnEvent,
  handleGetAppManifest,
  handleMigrateAction,
};
