import { get } from 'lodash';
import {
  EXPERIMENT_MAP_OLD_LAYOUTS_TO_PRO_GALLERY_IN_EDITOR,
  EXPERIMENT_ONE_COLUMN_MAX_HEIGHT_SETTING,
} from '@wix/communities-blog-experiments';
import maIntegrationKit from './ma-integration-kit';
import { isLayoutProGallery } from '@wix/communities-blog-client-common';
import mapStyleParamsToPG from './map-pg-layout-styles';
import { blogAppDefId } from '../constants/apps';
import { getPanelOptions } from './settings-panels';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import { getStyleProperty, HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL } from '../constants/style-params';
import bi from './bi';
import monitoring from './monitoring';
import experiments from './experiments';
import page from './page';
import { LAYOUT_PANEL, MAIN_PANEL } from '../constants/settings-panels';
import { openModalPanel } from './modal';
import { BLOG_WIDGET_ID } from '../constants/widgets';
import retry from './retry';

export const installMissingPostPage = async ({ sdk, appToken, instance }) => {
  let currentPage, isPostPageMissing;
  try {
    [currentPage, isPostPageMissing] = await Promise.all([
      sdk.document.pages.getPrimary(),
      page.isPostPageMissing({ sdk, appToken, instance }),
    ]);
  } catch (e) {
    console.log('Error checking for missing post page:');
    console.log(e);
  }

  if (isPostPageMissing) {
    await sdk.tpa.add.component(appToken, {
      componentType: 'PAGE',
      appDefinitionId: blogAppDefId,
      page: { pageId: TPA_PAGE_ID_POST },
    });

    return sdk.document.pages.navigateTo(appToken, { pageRef: currentPage });
  }

  return Promise.resolve();
};

export const initProvision = async ({ sdk }) => {
  try {
    await monitoring.toMonitored('sdk.document.save', retry(sdk.document.save, 5, 100), true);
    return true;
  } catch (_) {
    return false;
  }
};

export const initBiService = async ({ sdk, appToken }) => {
  bi.init(await sdk.document.info.getAppInstance(appToken));
};

export const displayProvisioningModal = async ({ sdk, appToken, isADI }) => {
  try {
    bi.provisioningModalDisplayed();
    const appData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
    const url = get(appData, 'appFields.platform.baseUrls.provisioningModalUrl', '');
    const modal = { url, width: 744, height: 448, shouldHideHeader: true };
    const { action, value } = await openModalPanel({ sdk, appToken, isADI, modal });
    bi.provisioningModalClosed(action, value);

    const shouldInstallMembersArea = action === 'next' && value;
    if (shouldInstallMembersArea) {
      await maIntegrationKit.installMembersArea();
    }
  } catch (error) {
    console.warn(error);
  }
};

const openSettingsPanel = (panelType) => async ({ sdk, appToken, instance }, componentRef, componentRole) => {
  const options = await getPanelOptions({ sdk, instance, componentRef, panelType, componentRole });

  return sdk.editor.openComponentPanel(appToken, {
    componentRef,
    ...options,
  });
};

const openMainSettings = openSettingsPanel(MAIN_PANEL);
const openLayoutSettings = openSettingsPanel(LAYOUT_PANEL);

export const setStyleParams = async ({ sdk, appToken, isADI, isClassicEditor }) => {
  const isMapToPGEnabled = experiments.isEnabled(EXPERIMENT_MAP_OLD_LAYOUTS_TO_PRO_GALLERY_IN_EDITOR);

  if (isADI || (isClassicEditor && !isMapToPGEnabled)) {
    return;
  }

  const blogAppData = await sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId);
  const blogAppComponents = await sdk.document.tpa.app.getAllCompsByApplicationId(appToken, blogAppData.applicationId);
  const blogComponent = blogAppComponents.find((component) => component.widgetId === BLOG_WIDGET_ID);
  if (!blogComponent) {
    return;
  }
  const blogComponentRef = await sdk.document.components.getById(appToken, { id: blogComponent.id });
  const blogComponentStyle = await sdk.components.style.get(appToken, { componentRef: blogComponentRef });

  const homepageLayoutType = getStyleProperty(blogComponentStyle, HOMEPAGE_LAYOUT_TYPE_STYLE_PARAM_FULL);

  if (isLayoutProGallery(homepageLayoutType)) {
    return;
  }

  const isOneColumnPGLayoutEnabled = experiments.isEnabled(EXPERIMENT_ONE_COLUMN_MAX_HEIGHT_SETTING);
  const styleParams = mapStyleParamsToPG(blogComponentStyle, isClassicEditor, isOneColumnPGLayoutEnabled);

  if (styleParams.length < 1) {
    return;
  }

  await sdk.document.tpa.setStyleParams(appToken, {
    compRef: blogComponentRef,
    styleParams,
  });
};

export default {
  initProvision,
  initBiService,
  installMissingPostPage,
  displayProvisioningModal,
  openLayoutSettings,
  openMainSettings,
  setStyleParams,
};
