export const MIGRATION_STEPS = {
  NOT_STARTED: 'Migration not started',
  CONTENT_MIGRATION_STARTED: 'Content migration',
  CLIENT_MIGRATION_CAN_MIGRATE: 'Client migration - can migrate check',
  CLIENT_MIGRATION_DISABLE_AUTOSAVE: 'Client migration - disable autosave',
  CLIENT_MIGRATION_OLD_BLOG_FEED_CONVERSION: 'Client migration - convert old blog feed page type',
  CLIENT_MIGRATION_OLD_BLOG_POST_PAGE_CONVERSION: 'Client migration - disable autosave',
  CLIENT_MIGRATION_COMPONENTS_REPLACE: 'Client migration - component replacement',
  CLIENT_MIGRATION_OLD_COMPONENTS_REMOVAL: 'Client migration - old blog components removal',
  CLIENT_MIGRATION_NEW_BLOG_PAGES_REMOVAL: 'Client migration - new blog pages removal',
  CLIENT_MIGRATION_STYLE_PARAMS_MIGRATION: 'Client migration - style params migration',
  CLIENT_MIGRATION_FINISHED: 'Client migration - finished',
};
