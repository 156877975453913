import { blogAppDefId } from '../constants/apps';
import { RELATIVE_API_URL } from '../constants/api';
import { TPA_PAGE_ID_POST } from '../constants/tpa-pages';
import retry from './retry';

export const getBlogApiUrl = (instance) => `${RELATIVE_API_URL}/_api/blogs/this?instance=${instance}`;

const find = async ({ sdk, appToken, tpaPageId }) => {
  const res = await Promise.all([
    sdk.tpa.app.getDataByAppDefId(appToken, blogAppDefId),
    sdk.pages.data.getAll(appToken),
  ]);
  const blogAppData = res[0] || {};
  const allPages = res[1] || [];
  return allPages.find((page) => page.tpaPageId === tpaPageId && page.tpaApplicationId === blogAppData.applicationId);
};

const fetchPostPageEnabled = async (instance) =>
  fetch(getBlogApiUrl(instance), { method: 'GET', headers: { 'Content-Type': 'application/json' } })
    .then((res) => {
      if (res.status === 404) {
        throw new Error('Blog not found');
      }
      return res.json();
    })
    .then(({ postPageEnabled }) => postPageEnabled);

const isPostPageEnabled = async (instance) => await retry(() => fetchPostPageEnabled(instance));

const isPostPageMissing = async ({ sdk, appToken, instance }) => {
  if (!instance) {
    instance = await sdk.document.info.getAppInstance(appToken);
  }

  const [postPage, postPageEnabled] = await Promise.all([
    find({ sdk, appToken, tpaPageId: TPA_PAGE_ID_POST }),
    isPostPageEnabled(instance),
  ]);

  return Boolean(!postPage && postPageEnabled);
};

export default { find, isPostPageMissing, isPostPageEnabled };
